import { defineStore } from "pinia"
import router from "@/router/index"
import localCache from "@/utils/cache"
import { TOKEN_KEY } from "@/constant/login"
import { logOut } from "@/service/api/login/index"
import { myInformation } from "@/service/api/home/index"
import type { IUserInfoRes } from "@/service/api/home/type"
interface UserState {
  token: string
  userInfo: IUserInfoRes
  lastUpdateTime: number
}
function initUserInfo() {
  return {
    head_img: "",
    mobile: "",
    nickname: "",
    qq: "",
    user_mobile: "",
    wx: ""
  }
}
export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    token: "",
    userInfo: initUserInfo(),
    lastUpdateTime: 0
  }),
  getters: {
    getToken(): string {
      return this.token || localCache.getCache(TOKEN_KEY)
    },
    getUserInfo(): any {
      return this.userInfo
    },
    getLastUpdateTime(): number {
      return this.lastUpdateTime
    }
  },
  actions: {
    setToken(token: string) {
      if (token) {
        this.token = token || ""
        localCache.setCache(TOKEN_KEY, token)
      }
    },
    setUserInfo(info: any) {
      this.userInfo = info
      this.lastUpdateTime = new Date().getTime()
    },
    async getUserInfoAction(): Promise<any | null> {
      try {
        if (!this.getToken) {
          return null
        }
        const userInfoRes = await myInformation()
        const userInfo = userInfoRes.data
        this.setUserInfo(userInfo)
        return userInfo
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async logOutAction() {
      try {
        if (!this.getToken) {
          return null
        }
        const res = await logOut()
        if (res.code === 200) {
          localCache.deleteCache(TOKEN_KEY)
          this.setToken("")
          // this.setUserInfo(initUserInfo())
          router.replace({ name: "login" })
        } else {
          throw new Error(res.msg)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
})
