import { showFailToast } from "vant"
import { useUserStore } from "@/store/modules/user"
import router from "@/router"
import localCache from "@/utils/cache"
import { TOKEN_KEY } from "@/constant/login"
export function checkStatus(status: number, msg: string): void {
  const userStore = useUserStore()
  let errMessage = ""
  switch (status) {
    case 400:
      errMessage = `${msg}`
      break
    case 401:
      userStore.setToken("")
      localCache.deleteCache(TOKEN_KEY)
      router.replace({ name: "login" })
      errMessage = msg || "用户没有权限"
      break
    case 403:
      errMessage = "用户得到授权，但是禁止访问"
      break
    // 404请求不存在
    case 404:
      errMessage = "网络请求错误，未找到该资源"
      break
    case 405:
      errMessage = "网络请求错误，请求方法未被允许"
      break
    case 408:
      errMessage = "网络请求超时！"
      break
    case 500:
      errMessage = "服务器错误，请联络技术部"
      break
    case 501:
      errMessage = "网络未实现！"
      break
    case 502:
      errMessage = "网络错误！"
      break
    case 503:
      errMessage = "服务不可用，服务器过载或者正在维护"
      break
    case 504:
      errMessage = "网络超时！"
      break
    case 505:
      errMessage = "http版本不支持该请求"
      break
    default:
  }
  if (errMessage) {
    showFailToast({ message: errMessage })
  }
}
