import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import pinia from "./store"
import "normalize.css"
import "./assets/css/common.css"
import "./utils/rem.js"
import "vant/es/toast/style"
import "vant/es/dialog/style"
import "./assets/font/font.css"
import VConsole from "vconsole"
const app = createApp(App)
app.use(pinia)
app.use(router)
app.mount("#app")
//如果不是生产环境并且不是pc设备那么就显示调试
if (process.env.NODE_ENV != "production") {
  new VConsole()
}
