import qynRequest from "../../index"
import { IDataType, UploadFileParams } from "../../types"
import { IUserInfoRes, EditUserInfo, IOrderStatus } from "./type"
enum userApi {
  MyInformation = "/myInformation",
  EditUserInformation = "/editUserInformation",
  UploadImage = "/uploadImage",
  GetOrderStatus = "/orderStatus",
  CancelOrder = "/cancelOrder",
  Trading = "/trading"
}
export function myInformation() {
  return qynRequest.post<IDataType<IUserInfoRes>>({
    url: userApi.MyInformation
  })
}
export function editUserInformation(data: EditUserInfo) {
  return qynRequest.post<IDataType>({
    url: userApi.EditUserInformation,
    data
  })
}
export function getOrderStatus(data: IOrderStatus) {
  return qynRequest.post<IDataType>({
    url: userApi.GetOrderStatus,
    data
  })
}
export function cancelOrder(data: IOrderStatus) {
  return qynRequest.post<IDataType>({
    url: userApi.CancelOrder,
    data
  })
}
export function trading(data: any) {
  return qynRequest.post<IDataType>({
    url: userApi.Trading,
    data
  })
}

export function upload(data: UploadFileParams) {
  return qynRequest.uploadFile(
    {
      url: userApi.UploadImage
    },
    data
  )
}
