// service统一出口
import QYNRequest from "./request/request"
import localCache from "@/utils/cache"
import { TOKEN_KEY } from "@/constant/login"

const qynRequest = new QYNRequest({
  baseURL: process.env.VUE_APP_BASE_URL,
  interceptors: {
    requestInterceptor: (config) => {
      const token = localCache.getCache(TOKEN_KEY)
      let configOptions: any = {
        ...config
      }
      if (token && config.headers) {
        configOptions.headers["Authorization"] = token
      }
      return configOptions
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (data) => {
      return data
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export default qynRequest
