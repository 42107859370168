import axios from "axios"
import qs from "qs"
import type { AxiosInstance } from "axios"
import { checkStatus } from "./checkStatus"
import { UploadFileParams } from "../types"
import type { IRequestInterceptors, IRequestConfig } from "./types"
class QYNRequest {
  instance: AxiosInstance
  interceptors?: IRequestInterceptors
  constructor(config: IRequestConfig) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )
    this.instance.interceptors.request.use(
      (config) => {
        return config
      },
      (err) => {
        return err
      }
    )
    this.instance.interceptors.response.use(
      (res) => {
        const data = res.data
        if (data) {
          if (data.code !== 200) {
            checkStatus(data.code, data.msg)
            return data
          } else {
            return data
          }
        } else {
          console.error("接口请求失败")
        }
      },
      (err) => {
        if (err.response.status === 404) {
          console.log("404")
        }
        return err
      }
    )
  }
  request<T = any>(config: IRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptor) {
        config == config.interceptors.requestInterceptor(config)
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  get<T = any>(config: IRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "GET" })
  }
  post<T = any>(config: IRequestConfig<T>): Promise<T> {
    config.data = qs.stringify({ ...config.data })
    return this.request<T>({
      ...config,
      method: "POST"
    })
  }
  uploadFile<T = any>(config: IRequestConfig<T>, params: UploadFileParams) {
    const formData = new window.FormData()
    const customFilename = params.name || "file"

    if (params.filename)
      formData.append(customFilename, params.file, params.filename)
    else formData.append(customFilename, params.file)

    if (params.data) {
      Object.keys(params.data).forEach((key) => {
        const value = params.data[key]
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(`${key}[]`, item)
          })
          return
        }
        formData.append(key, params.data[key])
      })
    }

    return this.request<T>({
      ...config,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8"
      }
    })
  }
  delete<T = any>(config: IRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" })
  }
  patch<T = any>(config: IRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PATCH" })
  }
}

export default QYNRequest
