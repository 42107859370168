import qynRequest from "../../index"
import { IDataType } from "../../types"
import { IRegisterForm, IMobileForm, IPasswordForm, ICodeLoign } from "./type"
enum loginApi {
  UserRegister = "/userRegister",
  GetVerifyCode = "/verifyCode",
  Login = "/login",
  LogOut = "/logout",
  WxCodeLogin = "/wxCodeLogin",
  WxRegisterCodeLogin = "/wxRegisterCodeLogin",
  GetSmsLoginCode = "/smsLoginCode",
  SmsCode = "/smsCode",
  FindPassword = "/findPassword",
  SmsLogin = "/smsLogin"
}
export function userRegister(data: IRegisterForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.UserRegister,
    data
  })
}
export function getVerifyCode(data: IMobileForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.GetVerifyCode,
    data
  })
}
export function getSmsLoginCode(data: IMobileForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.GetSmsLoginCode,
    data
  })
}
export function getSmsCode(data: IMobileForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.SmsCode,
    data
  })
}
export function findPassword(data: IPasswordForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.FindPassword,
    data
  })
}
export function login(data: IPasswordForm) {
  return qynRequest.post<IDataType>({
    url: loginApi.Login,
    data
  })
}
export function logOut() {
  return qynRequest.post<IDataType>({
    url: loginApi.LogOut
  })
}
export function wxCodeLogin(data: any) {
  return qynRequest.post<IDataType>({
    url: loginApi.WxCodeLogin,
    data
  })
}
export function wxRegisterCodeLogin(data: any) {
  return qynRequest.post<IDataType>({
    url: loginApi.WxRegisterCodeLogin,
    data
  })
}
export function smsLogin(data: ICodeLoign) {
  return qynRequest.post<IDataType>({
    url: loginApi.SmsLogin,
    data
  })
}
