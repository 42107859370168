import qynRequest from "../../index"
import { IDataType, UploadFileParams } from "../../types"
enum Api {
  InformationList = "/informationList",
  InformationClassList = "/informationClassList",
  UploadImage = "/uploadImage",
  AddInformation = "/addInformation",
  InformationInfo = "/informationInfo"
}
export function informationList(data: any) {
  return qynRequest.post<IDataType>({
    url: Api.InformationList,
    data
  })
}
export function informationClassList() {
  return qynRequest.post<IDataType>({
    url: Api.InformationClassList
  })
}
export function addInformation(data: any) {
  return qynRequest.post<IDataType>({
    url: Api.AddInformation,
    data
  })
}
export function informationInfo(data: any) {
  return qynRequest.post<IDataType>({
    url: Api.InformationInfo,
    data
  })
}
export function uploadImage(params: UploadFileParams) {
  return qynRequest.uploadFile<IDataType>(
    {
      url: Api.UploadImage
    },
    params
  )
}
