import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { useUserStore } from "@/store/modules/user"
import Index from "../views/index/index.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: Index
  },
  {
    path: "/sell",
    name: "sell",
    meta: {
      auth: true
    },
    component: () => import("@/views/sell/index.vue")
  },
  {
    path: "/entry",
    name: "entry",
    meta: {
      keepAlive: true,
      auth: true
    },
    component: () => import("@/views/entry/index.vue")
  },
  {
    path: "/entryArticle",
    name: "entryArticle",
    component: () => import("@/views/entryArticle/index.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/home",
    name: "home",
    meta: {
      auth: true
    },
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/detail/:id",
    name: "detail",
    meta: {
      auth: true
    },
    component: () => import("@/views/detail/index.vue")
  },
  {
    path: "/list",
    name: "list",
    meta: {
      auth: true
    },
    component: () => import("@/views/list/index.vue")
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/views/protocol/index.vue")
  },
  {
    path: "/goodsList",
    name: "goodsList",
    component: () => import("@/views/goods/goodsList/index.vue")
  },
  {
    path: "/goodsDetail/:id",
    name: "goodsDetail",
    component: () => import("@/views/goods/detail/index.vue")
  },
  {
    path: "/addArticle",
    name: "addArticle",
    meta: {
      auth: true
    },
    component: () => import("@/views/article/addArticle/index.vue")
  },
  {
    path: "/articleList",
    name: "articleList",
    component: () => import("@/views/article/articleList/index.vue")
  },
  {
    path: "/articleDetail",
    name: "articleDetail",
    component: () => import("@/views/article/articleDetail/index.vue")
  },
  {
    path: "/addBountyOffered",
    name: "addBountyOffered",
    meta: {
      auth: true
    },
    component: () => import("@/views/article/addBountyOffered/index.vue")
  },
  {
    path: "/bountyOfferedList",
    name: "bountyOfferedList",
    component: () => import("@/views/article/bountyOfferedList/index.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const token = userStore.getToken
  if (to.meta.auth) {
    if (!token) {
      next("/login")
      return
    }
    if (userStore.getLastUpdateTime === 0) {
      try {
        await userStore.getUserInfoAction()
      } catch (err) {
        next()
        return
      }
    }
    next()
  } else {
    next()
  }
})
export default router
